@media print{
  .print-hide{
    display: none;
  }
  #root{
    font-size:11px;
    font-family:"Times New Roman";
  }

  h5{
    font-size: 0.8rem;
  }

  h3{
    font-size: 1rem
  }
}

h5{
  color: black;
  background: lightblue;
}

html { font-size: 62.5%; }